<template>
  <div>
    <v-card flat>
      <v-card-title>
        <label class="primary--text px-5 font-weight-bold info-page-title ma-auto">{{ $t(`label.responsibleGaming`) }}</label>
      </v-card-title>
    </v-card>

    <v-card class="info-page-card">
      <v-expansion-panels accordion v-model="panel">
        <v-expansion-panel v-for="info in responsibleGamingContent" :key="info.title">
          <v-expansion-panel-header class="font-weight-bold text-uppercase">{{ info.title }}</v-expansion-panel-header>
          <v-expansion-panel-content class="py-4 info-expension-panel-content">
            <div v-for="content in info.content" :key="content.title">
              <p class="text-capitalize font-weight-bold info-expension-panel-content-title mb-1" v-html="content.title"></p>
              <p v-html="content.desc"></p>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>
<script>
import { locale, uiHelper } from "@/util";
export default {
  name: 'responsibleGaming',
  data: () => ({
    panel: 0,
    responsibleGamingContent: [
      {
        title: locale.getMessage(`info.info_responsibleGaming`),
        content: [
          {
            title: locale.getMessage(`info.info_responsibleGaming_a_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_a_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_b_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_b_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_c_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_c_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_c1_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_c1_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_c2_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_c2_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_c3_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_c3_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_c4_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_c4_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_d_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_d_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_d1_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_d1_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_d2_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_d2_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_d3_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_d3_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_d4_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_d4_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_d5_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_d5_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_d6_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_d6_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_e_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_e_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_e1_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_e1_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_e2_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_e2_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_f_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_f_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_f1_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_f1_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_f2_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_f2_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_f3_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_f3_desc`)
          },
          {
            title: locale.getMessage(`info.info_responsibleGaming_f4_title`),
            desc: locale.getMessage(`info.info_responsibleGaming_f4_desc`)
          }
        ]
      }
    ]
  }),
  mounted() {
    this.metaTag()
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href;
      const domainWithProtocol = currentUrl.split('/')[2];
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '');
      const path = window.location.pathname;
      const cleanPath = path.replace(/\/(en-BD|bn-BD|en-IN|hi-IN|en-NP|ne-NP)/i, '');
      const Links = `https://${domain}${cleanPath}`;
      const Linksen = `https://${domain}/en-BD${cleanPath}`;
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`;
      const Linksenin = `https://${domain}/en-IN${cleanPath}`;
      const Linksenhi = `https://${domain}/hi-IN${cleanPath}`;
      const Linksenennp = `https://${domain}/en-NP${cleanPath}`;
      const Linksennenp = `https://${domain}/ne-NP${cleanPath}`;

      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
      uiHelper.setHreflangLinksenin(Linksenin)
      uiHelper.setHreflangLinkshi(Linksenhi)
      uiHelper.setHreflangLinksennp(Linksenennp)
      uiHelper.setHreflangLinksnenp(Linksennenp)
    }
  }
}
</script>
